
<template>
  <div  class="main-layout">

    <div class="title-info">
      <img src="../../assets/common/icon_swipe_left.png" alt="" @click="$router.go(-1)">
      <p>我的卡券</p>
    </div>

    <div  class="coupons-info">
      <div class="coupons-top">

        <div class="content-tabs">
          <van-tabs @click-tab="clickTab"  v-model:active="data.tabActive"  line-width="43px" line-height="3px" title-active-color="#313D4A" title-inactive-color="#7A8087">
            <van-tab v-for="item in data.tabArray" :title="item.title" :name="item.idx" :key="item.idx">

            </van-tab>
          </van-tabs>
          <p class="coupon-totol">可用<span style="color:#FB4D09">{{data.canUseCounts}}</span>张/共{{data.couponCounts}}张</p>
        </div>


      </div>

      <div v-if="data.couponList.length > 0" class="coupons-list">
          <div  v-for="(item,i) in data.couponList" :style="{'--borderColor': item.couponStatus == 3  ||  item.couponStatus == 4? '#BFBFBF' : 'rgba(251,77,9,0.5)'}" :key="i" class="coupon-item" :class="item.couponStatus == 3  ||  item.couponStatus == 4? 'coupon-item-gray' : ''">



            <div class="coupon-item-left">
              <p class="coupon-name" :class="item.couponStatus == 3  ||  item.couponStatus == 4 ? 'coupon-name-gray' : ''">{{item.title}}</p>
              <p class="coupon-time">有效期：{{item.dateStr}}</p>
            </div>
            <div class="coupon-item-right">

              <p :style="'font-size: 12px;color:'+ (item.couponStatus == 3  ||  item.couponStatus == 4 ? '#D2D2D2' : '#FB4D09') +';'"><span style="font-weight: bold;font-size: 24px;">{{item.amount}}</span>元</p>
              <img v-if="item.couponStatus == 3" src="../../assets/mine/icon_coupon_expire.png" alt="">
              <img v-else-if="item.couponStatus == 4" src="../../assets/mine/icon_coupon_used.png" alt="">
              <button v-else-if="item.couponStatus == 1 || item.couponStatus == 2"  class="use-btn" @click="toCouptonDetail(item)">去使用</button>



            </div>

          </div>
      </div>
      <div class="no-data" v-else>
        <img src="../../assets/nodata/icon_no_wc.png" alt="">
        <p>暂无内容</p>
      </div>

    </div>




  </div>
</template>

<script>
import {onMounted, toRefs, getCurrentInstance, reactive,} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";



import {
  changeToDate,
  clearToast,
  formatDate, getStore, loginToPlatform,
  setStore,
  showFailToast,
  showLoading,
  showSuccessToast
} from "../../utils/util";


export default {
  name: "myCoupons",
  components: {

  },
  setup(props, context) {

    //埋点上报
    eshimin.bytedanceApplogEvent({
      eventName: 'page_detail', // 例子
      eventParams: {'page_name': '我的-券包','title_name': '我的-券包'} ,//例子
      onResponse:  (res)=> {
        console.log("bytedanceApplogEvent responseData:" + res);
      }
    })

    const data = reactive({
      couponList:[],
      canUseCounts:0,
      couponCounts:0,
      currentType:1,
      tabActive:0,
      orderList: [],
      tabArray: [
        {
          title: "全部",
          idx: 1
        },
        {
          title: "未使用",
          idx: 2
        },
        {
          title: "已使用",
          idx: 4
        },{
          title: "已过期",
          idx: 3
        }],
    })

    document.title = '我的卡券'
    const { proxy } = getCurrentInstance();
    const $store = useStore();
    const $router = useRouter();

    onMounted(() => {

    })

    const toCouptonDetail = (item) => {
      $router.push({name:'myCouponDetail',query:{data:JSON.stringify(item)}})

    }



    const loadData = () => {

      // 1:全部
      // 2:未使用
      // 3:已使用
      // 4:已过期

      showLoading()
      proxy.$http.get("/wenwu-custom-services/bookstore/coupon/list/" + data.currentType ).then(res => {
        clearToast()
        data.couponCounts =  res.data.data.couponCounts
        data.canUseCounts =  res.data.data.canUseCounts
        data.couponList = res.data.data.couponList

      })
    }

    const clickTab = (info) => {

      data.currentType = info.name;
      loadData()


    }

    loadData();




    return {
      data,
      toCouptonDetail,
      clickTab
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>

